import { observable, action, makeObservable, computed } from "mobx";
import { reactLocalStorage } from "reactjs-localstorage";

class AuthStore {
  currentToken = null;
  currentUser = null;
  authLoading = false;

  constructor() {
    const currentUserString = reactLocalStorage.get("admin");

    if (currentUserString) {
      this.currentUser = JSON.parse(currentUserString);
    }

    const currentTokenString = reactLocalStorage.get("token");
    if (currentTokenString) {
      this.currentToken = currentTokenString;
    } else {
      this.currentUser = null;
      this.currentToken = null;
    }
  }
}

makeObservable(AuthStore, {
//   currentUser: observable,
//   currentToken: observable,
//   authLoading: observable,
//   isAuthenticated: computed,
//   signInWithEmailAndPassword: action,
//   getAdminPermission: action,
//   signOut: action
});

export default new AuthStore();
