import { action, makeObservable, observable, computed } from "mobx";

class CommonStore {
  snackbarVariant = "success";
  snackbarMessage = "";
  snackbarShouldOpen = false;

  showAppSnackbar = ({ variant, message }) => {
    this.snackbarMessage = message;
    this.snackbarVariant = variant;
    this.snackbarShouldOpen = true;
  };

  closeAppSnackbar = () => {
    this.snackbarShouldOpen = false;
  };

  showSuccessMessage = message => {
    this.showAppSnackbar({
      variant: "success",
      message
    });
  };

  showErrorMessage = message => {
    if (message && typeof message === "string") {
      this.showAppSnackbar({
        variant: "error",
        message
      });
    }
  };
}

makeObservable(CommonStore, {
  
  // snackbarVariant: observable,
  // snackbarMessage: observable,
  // snackbarShouldOpen: observable,
  
  // showSuccessMessage: action,
  // showErrorMessage: action,
  // showAppSnackbar: action,
  // closeAppSnackbar: action,

});

export default new CommonStore();
