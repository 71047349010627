import React, { Suspense, useEffect } from "react";
import { Provider } from "mobx-react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import './App.css';

// import "@coreui/icons/dist/css/coreui-icons.min.css";
// Import Flag Icons Set
// import "flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Toastify
import 'react-toastify/dist/ReactToastify.css';

import "./scss/style.css";
import "./scss/custom-style.css";
import "./scss/customImage.css";
import "./scss/loading.css";
// Containers
// import "react-datepicker/dist/react-datepicker.css";

import stores from "./stores";

const AppSnackbar = React.lazy(() => import("./components/AppSnackbar"));
// const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const MainScreen = React.lazy(() => import("./views/MainScreen"));
const CSMainScreen = React.lazy(() => import("./views/CSMainScreen"));
const ChatRouting = React.lazy(() => import("./views/ChatRouting"));
const ChatList = React.lazy(() => import("./views/Chat/ChatWindow/ChatList"));
const Chat = React.lazy(() => import("./views/Chat/ChatWindow/Chat"));
const Attendance = React.lazy(() => import("./views/Attendance/Attendance"));
const VideoCall = React.lazy(() => import("./views/VideoCall/VideoCall"));
const News = React.lazy(() => import("./views/News/News"));

const version = '0.1.0';

document.body.style = 'background:white;'

function App() {
  
  useEffect(() => {
    console.log(`Version: ${version}`);
  }, [])

  return (
    <Provider {...stores}>
        <React.Fragment>
          <Suspense fallback={<div></div>}>
            <AppSnackbar />
          </Suspense>
          <Suspense fallback={<div></div>}>
            <BrowserRouter>
              <React.Fragment>
                <Routes>
                  <Route exact path={`/attendance/:status/:adminId`} name="Attendance" element={<Attendance />} />
                  <Route exact path={`/:region/chats/:chatid`} name="Chat" element={<Chat />} />
                  <Route exact path={`/:region/chats`} name="Chat List" element={<ChatList />} />
                  <Route exact path={`/:region/cs/:chattoken`} element={<CSMainScreen/>} />
                  <Route exact path={`/:region/:chattoken`} element={<MainScreen/>} />
                  <Route exact path={`/:region/routes/:routes`} element={<ChatRouting/>} />
                  <Route exact path={`/news`} element={<News/>} />
                  <Route exact path={`/videocall/:chatid/:userid/:toid/:startCall`} name="Chat" element={<VideoCall />} />
                  <Route exact path={`/videocall`} name="Chat" element={<VideoCall />} />
                </Routes>
              </React.Fragment>
            </BrowserRouter>
          </Suspense>
        </React.Fragment>
    </Provider>
  );
}

export default App;
